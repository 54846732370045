import React, { useState, useEffect } from "react";
import axios from "axios";

const AiHelper = () => {
  const [dropdownValue, setDropdownValue] = useState(2);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [apiResponse, setApiResponse] = useState({
    answer: "",
    answerIsFromCache: false,
  });
  const [useCaseNames, setUseCaseNames] = useState([]);
    const baseUrl = 'https://aiservice.expressit.se/AiServices'; // prod
//   const baseUrl = "http://localhost:5110/AiServices"; // dev

  useEffect(() => {
    const fetchUseCaseNames = async () => {
      try {
        const response = await axios.get(`${baseUrl}/GetUseCases`);
        setUseCaseNames(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUseCaseNames();
  }, []);

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
    setTextEdited(true);
    if (event.target.value.length >= 5000) {
      alert("No more than 5000 characters allowed.");
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [textEdited, setTextEdited] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setTextEdited(false);
      setApiResponse({
        answer: "",
        answerIsFromCache: false,
      });
      const userQuestion = {
        UseCaseId: dropdownValue,
        Question: textAreaValue,
      };

      const response = await axios.post(`${baseUrl}/GetAnswer`, userQuestion);
      setApiResponse(response.data.value);
      console.log(apiResponse);
    } catch (error) {
      console.error(error);
      alert(
        "Something went wrong when trying to get the answer from the server."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Add a spinner when isLoading is true
  const renderSpinner = () => {
    return isLoading ? <div className="spinner"></div> : null;
  };

  const handleClear = () => {
    setTextAreaValue("");
    setApiResponse({
      answer: "",
      answerIsFromCache: false,
    });
  };

  return (
    <div className="container">
      <select value={dropdownValue} onChange={handleDropdownChange}>
        {useCaseNames.map((useCase) => (
          <option key={useCase.id} value={useCase.id}>
            {useCase.name}
          </option>
        ))}
      </select>

      <textarea
        maxLength={5000}
        value={textAreaValue}
        onChange={handleTextAreaChange}
      />

      <div className="button-container">
        <button className="small-button, clear-button" onClick={handleClear}>
          Clear
        </button>
        <button
          className="small-button, submit-button"
          onClick={handleSubmit}
          disabled={!textEdited || textAreaValue.length < 1}
        >
          Submit
        </button>
      </div>
      <div className="status" style={{ display: isLoading ? "block" : "none" }}>
        Waiting for AI response...
      </div>
      <div className="answer" style={{ whiteSpace: "pre-wrap" }}>
        <p>{apiResponse.answer}</p>
      </div>
      <div className="additionalinfo">
        <p>Answer is from cache: {apiResponse.answerIsFromCache.toString()}</p>
      </div>
    </div>
  );
};

export default AiHelper;
